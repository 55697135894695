import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form"
export default class extends Controller {
  outputTarget: HTMLElement
  templateTarget: HTMLElement

  static targets = ["output", "template"]

  add(e: Event): void {
    e.preventDefault()

    const content: string = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.outputTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove(e: Event): void {
    e.preventDefault()

    if ((e.target instanceof HTMLElement)) {
      const wrapper: HTMLDivElement = e.target.closest('.nested-form-wrapper')
      const destroy_input: HTMLInputElement = wrapper.querySelector("input[name*='_destroy']")

      wrapper.style.display = "none"
      destroy_input.value = "1"
    }
  }
}
